<template>
  <div>
    <el-form label-width="80px">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="状态:">
            <el-select style="width:100%"
                       @change="search"
                       v-model="form.status"
                       placeholder="请选择">
              <el-option v-for="item in statusList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="身份:">
            <el-select style="width:100%"
                       v-model="form.role"
                       clearable
                       filterable
                       placeholder="请选择">
              <el-option v-for="item in roleList"
                         :key="item.id"
                         :label="item.role_name"
                         :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="搜索:">
            <el-input clearable
                      placeholder="请输入账号或姓名"
                      v-model="form.keyword"
                      style="width:100%"
                      @keyup.enter.native="search" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <!-- <el-button type="primary"
                     @click="toAdd">添加管理员</el-button> -->
        </el-col>
      </el-row>
    </el-form>

    <Table ref="TablesRef"
           :tableData="tableData" />
    <addForm ref="formRef" />
    <passwordForm ref="passwordFormRef" />

    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import addForm from './components/form.vue'
import passwordForm from './components/passwordForm.vue'
export default {
  data () {
    return {
      statusList: [{ value: 0, label: '不显示' },
      { value: 1, label: '显示' },
      { value: '', label: '全部' }],
      form: {
        status: '',
        keyword: '',
        role: '',
      },
      tableData: [],
      roleList: [],
      page: 1,
      size: 10,
      totalElements: 0,
      pageName: 'settingAdminListAdmin',
      tableColumns: [
        { prop: 'real_name', align: 'center', label: '姓名', width: '', showToopic: false },
        { prop: 'account', align: 'center', label: '账号', width: '', showToopic: false },
        { prop: 'last_time', align: 'center', label: '最后一次登录时间', width: '', showToopic: false },
        { prop: 'last_ip', align: 'center', label: '最后一次登录ip', width: '', showToopic: false },
        { prop: 'status', align: 'center', label: '是否开启', width: '', showToopic: false, isStatus: true },
        {
          prop: 'd', align: 'center', label: '操作', width: '180', type: 'img', btns: [
            { content: '修改信息', type: 'edit', event: 'editRow' },
            { content: '删除', type: 'del', event: 'delRow' },
            { content: '修改密码', type: 'edit', event: 'editPassword' },
          ]
        },
      ]
    }
  },
  components: {
    addForm, passwordForm
  },
  mounted () {
    this.getRolesList()
    this.setTablesColums()

  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    getClass (prop, value) {
      if (prop == 'status') {
        return value ? 'pass' : 'notpass'
      }
    },
    getValue (prop, value) {
      if (prop == 'status') {
        return value ? '是' : '否'
      }
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    initData (page, limit) {
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/tc_power/admin_lst',
        method: 'GET',
        params: form
      }).then(res => {
        for (let index = 0; index < res.data.list.length; index++) {
          let row = res.data.list[index]
          row.coles_cn_names = row.role_cn.join(',')
        }
        this.tableData = res.data.list

        this.totalElements = res.data.count
      })
    },
    getRolesList () {
      this.$http({
        url: '/api/v2/tc_power/role_lst',
        method: 'GET',
      }).then(res => {
        this.roleList = res.data.list
      })
    },
    toAdd () {
      let form = {}
      this.$refs.formRef.dialogVisible = true
    },
    delRow (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v2/tc_power/admin_del',
          method: 'post',
          data: {
            id: row.id
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.initData()
        })
      })
    },
    editRow (row) {
      let { id, account, real_name, roles, status } = JSON.parse(JSON.stringify(row))
      roles = roles ? roles.split(',') : ''
      let form = { id, account, real_name, roles, status }
      this.$refs.formRef.form = form
      this.$refs.formRef.text = '修改'
      this.$refs.formRef.getRolesList()
      this.$refs.formRef.dialogVisible = true
    },
    editPassword (row) {
      let form = {
        password: '',
        password1: '',
        id: row.id
      }
      this.$refs.passwordFormRef.form = form
      this.$refs.passwordFormRef.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.setting {
  cursor: pointer;
}
</style>
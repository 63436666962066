<template>
  <div>
    <el-dialog :title="text+'管理员'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="600px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               :rules="rules"
               label-width="110px">
        <el-row>
          <el-form-item label="管理员账号"
                        prop="account">
            <el-input clearable
                      v-model="form.account"
                      placeholder="请输入账号" />
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="管理员姓名"
                        prop="real_name">
            <el-input clearable
                      v-model="form.real_name"
                      placeholder="请输入管理员姓名" />
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="管理员身份"
                        prop="roles">
            <el-select v-model="form.roles"
                       multiple
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="(item,index) in rolesList"
                         :key="index"
                         :label="item.role_name"
                         :value="item.id + ''">
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="状态"
                        prop="status">
            <el-radio-group v-model="form.status">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-row>

      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      text: '新增',
      form: {},
      rolesList: [],
      rules: {
        account: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        real_name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        roles: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        status: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
      }
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.$refs.formRef.resetFields()
    },
    handleSave () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.roles = form.roles.join(',')
        this.$http({
          url: '/api/v2/tc_power/admin_edit',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.$parent.initData()
          this.handleClose()
        })
      })
    },
    getRolesList () {
      this.$http({
        url: '/api/v2/tc_power/role_lst',
        method: 'get',
      }).then(res => {
        this.rolesList = res.data.list
      })
    }

  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
</style>
<template>
  <div>
    <el-dialog title="修改密码"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="600px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               :rules="rules"
               label-width="110px">
        <el-row>
          <el-form-item label="管理员密码"
                        prop="password">
            <el-input clearable
                      type="password"
                      v-model="form.password"
                      placeholder="请输入密码" />
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="确认密码"
                        prop="password1">
            <el-input clearable
                      type="password"
                      v-model="form.password1"
                      placeholder="请确认密码" />
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    var checkPwd = (rule, value, callback) => {
      if (this.form.password1 != this.form.password) {
        return callback(new Error('两次密码不一致'));
      } else {
        return callback()
      }
    };
    return {
      dialogVisible: false,
      text: '修改',
      form: {},
      rules: {
        password: [
          { required: true, message: '请输入', trigger: 'blur' },
          { min: 6, max: 8, message: '长度在 6 到 8 个字符', trigger: 'blur' }
        ],
        password1: [
          { validator: checkPwd, trigger: 'blur', required: true }
        ],
      }
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.$refs.formRef.resetFields()
    },
    handleSave () {
      let form = {
        id: this.form.id,
        password: this.form.password
      }
      this.$refs.formRef.validate(val => {
        if (!val) {
          return
        }
        console.log('form', form);
        this.$http({
          url: '/api/v2/tc_power/admin_pwd',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.$parent.initData()
          this.handleClose()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
</style>